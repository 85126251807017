export const useAuthApi = () => {
    const authLoginUser = async (email, password) => {
        try {
            const data = await $fetch("/api/auth/login", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: {
                    email: email,
                    password: password
                }
            });
            return data;

        } catch (e) {
            console.log(e);
        }
    }

    const authRefreshToken = async (refreshToken) => {
        try {
            const data = await $fetch("/api/auth/refreshtoken", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: {
                    refreshToken: refreshToken
                }
            });

            return data;
        } catch (e) {
            console.log(e);
        }
    }

    const authForgotPassword = async (email) => {
        try {
            const data = await $fetch("/api/auth/forgotpassword", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: {
                    email: email
                }
            })

            return data;
        } catch (e) {
            console.log(e);
        }
    }

    const authSendMagicLink = async (email) => {
        try {
            const data = await $fetch("/api/auth/magiclink", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: {
                    email: email
                }
            });
            return data;
        } catch (e) {
            console.log(e);
        }
    }

    const authVerifyMagicLink = async (email, code) => {
        try {
            if (email && code) {
                const data = await $fetch("/api/auth/verifycode", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: {
                        email: email,
                        code: code
                    }
                });
                return data;
            }
        } catch (e) {
            console.log(e);
        }
    }

    const authSetPassword = async (id, code, password) => {
        try {
            if (id && code && password) {
                const data = await $fetch("/api/auth/setpassword", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: {
                        id: id,
                        code: code,
                        password: password
                    }
                });
                return data;
            }
        } catch (e) {
            console.log(e);
        }
    }

    return {
        authLoginUser,
        authRefreshToken,
        authForgotPassword,
        authSetPassword,
        authSendMagicLink,
        authVerifyMagicLink
    }
}