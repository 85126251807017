import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
    state: () => ({
        authenticated: false,
        authErrors: [],
        authMessage: null,
        loading: false
    }),
    actions: {
        async loginUser(email, password) {
            this.loading = true;
            const data = await useAuthApi().authLoginUser(email, password);

            if (data?.data?.authenticate) {
                useSetAuthCookies(
                    data?.data?.authenticate?.jwt,
                    data?.data?.authenticate?.jwtExpiresAt,
                    data?.data?.authenticate?.refreshToken,
                    data?.data?.authenticate?.refreshTokenExpiresAt
                );

                this.authenticatedErrors = [];
                this.authenticated = true;
            }

            if (data?.errors) {
                this.authErrors = data?.errors;
                this.authenticated = false;
            }
            this.loading = false;
        },

        async refreshToken() {
            const token = useCookie('PUQ_TOKEN');
            const refreshToken = useCookie('PUQ_REFRESH_TOKEN');

            this.authenticated = false;

            if (!token.value && refreshToken.value) {
                const data = await useAuthApi().authRefreshToken(refreshToken.value);

                if (data?.data?.refreshToken) {
                    useSetAuthCookies(
                        data?.data?.refreshToken?.jwt,
                        data?.data?.refreshToken?.jwtExpiresAt,
                        data?.data?.refreshToken?.refreshToken,
                        data?.data?.refreshToken?.refreshTokenExpiresAt
                    );

                    this.authErrors = [];
                    this.authenticated = true;
                } else {
                    this.logoutUser();
                }
            }
        },

        async forgotPassword(email) {
            this.loading = true;
            const data = await useAuthApi().authForgotPassword(email);

            if (data?.data?.forgottenPassword) {
                this.authMessage = data?.data?.forgottenPassword;
            }
            this.loading = false;
        },

        async setPassword(id, code, password) {
            this.loading = true;
            const data = await useAuthApi().authSetPassword(id, code, password);

            if (data?.data?.setPassword) {
                this.authMessage = data?.data?.setPassword;
            }

            if (data?.errors) {
                this.authErrors = data?.errors;
                this.authenticated = false;
            }

            this.loading = false;
        },

        async sendMagicLink(email) {
            this.loading = true;

            const data = await useAuthApi().authSendMagicLink(email);

            if (data?.data?.sendMagicLink) {
                this.authMessage = data?.data?.sendMagicLink;
            }

            this.loading = false;
        },

        async verifyCode(email, code) {
            this.loading = true;
            const data = await useAuthApi().authVerifyMagicLink(email, code);

            if (data?.data?.verifyMagicCode) {
                useSetAuthCookies(
                    data?.data?.verifyMagicCode?.jwt,
                    data?.data?.verifyMagicCode?.jwtExpiresAt,
                    data?.data?.verifyMagicCode?.refreshToken,
                    data?.data?.verifyMagicCode?.refreshTokenExpiresAt
                );

                this.authErrors = [];
                this.authMessage = null;
                this.authenticated = true;
            }

            if (data?.errors) {
                this.authErrors = data?.errors;
                this.authenticated = false;
            }

            this.loading = false;
        },

        logoutUser() {
            useSetAuthCookies(null, null, null, null);
            this.authErrors = [];
            this.authMessage = null;
            this.authenticated = false;
        },
    },
});