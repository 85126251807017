
export const useSetAuthCookies = (jwt, jwtExpiresAt, refreshToken, refreshTokenExpiresAt) => {
    if (jwt != null && refreshToken != null) {
        const currTimeStamp = new Date().getTime();
        const tokenCookie = useCookie('PUQ_TOKEN', { maxAge: Math.floor((jwtExpiresAt - currTimeStamp) / 1000) });
        const refreshTokenCookie = useCookie('PUQ_REFRESH_TOKEN', { maxAge: Math.floor((refreshTokenExpiresAt - currTimeStamp) / 1000) });
        tokenCookie.value = jwt;
        refreshTokenCookie.value = refreshToken;
    } else {
        const tokenCookie = useCookie('PUQ_TOKEN');
        const refreshTokenCookie = useCookie('PUQ_REFRESH_TOKEN');
        tokenCookie.value = jwt;
        refreshTokenCookie.value = refreshToken;
    }
}